import React from 'react'

const Header = (props) => {

  const Ismobile = props.isMobile

  if (Ismobile) {
    return (
      <div style={{
        background:'#7e2fd3',
      }}>

        <div>
          <img style={{width:'100%'}} src='https://www.rbs.co.uk/content/dam/natwest/personal/team-gb/image.dim.667.asha-default-banner.jpg' alt='' />
        </div>


        <div style={{margin:'50px 5%'}}>
          <h3 style={{color:'white',margin:0,fontWeight:400,fontSize:'0.8rem'}}>Proud Partner of Team GB</h3>
          <h1 style={{
            color:'white',
            fontSize:'2rem',
            margin:'10px 0px 30px 0px'
          }}>Whatever your goals, let's get going</h1>
  
          <button style={{marginBottom:'50px'}} className='headerbtn'>Find out more</button>
        </div>
  
        
  
        
      </div>
    )
  }else{
    return (
      <div style={{
        display: 'flex',
        background:'#7e2fd3',
        alignItems:'center',
        padding:15,
        justifyContent:'space-evenly'
      }}>
        <div style={{margin:30}}>
          <h3 style={{color:'white',margin:0,fontWeight:400}}>Proud Partner of Team GB</h3>
          <h1 style={{
            color:'white',
            fontSize:'3rem',
            margin:'10px 0px 30px 0px'
          }}>Whatever your goals, let's get going</h1>
  
          <button className='headerbtn'>Find out more</button>
        </div>
  
        
  
        <div>
          <img src='https://www.rbs.co.uk/content/dam/natwest/personal/team-gb/image.dim.667.asha-default-banner.jpg' alt='' />
        </div>
      </div>
    )
  }

  
}

export default Header
