import React from 'react'
import Navbar from '../navbar/Navbar'

const Login = () => {
  return (
    <div>
      <Navbar />
      <div style={{background:'#edeaea', height:'80vh',display:'grid',placeItems:'center'}}>
        <div>
         {/* <h1 style={{fontSize:'50px',textAlign:'center',color:"#3f1a73"}}>Apna Bank</h1> */}
        <div style={{width:400,background:'white',borderRadius:20,padding:30}}>
            <h1 style={{textAlign:'center'}}>Login</h1>
            <div style={{display:'flex',flexDirection:'column',marginBottom:20}}>
              <label>Customer ID</label>
              <input type='text' placeholder='User ID' style={{padding:8}}/>
            </div>

            <button style={{padding:"5px 15px",marginBottom:10,width:100,background:'#3f1a73',color:'white',borderRadius:15}}>Get OTP</button>

            <div style={{display:'flex',flexDirection:'column',marginBottom:10}}>
              <label>OTP</label>
                  <div style={{display:'flex'}}>
                    <input type='text' style={{padding:8,width:40,height:40,borderRadius:5,marginRight:5}}/>
                    <input type='text' style={{padding:8,width:40,height:40,borderRadius:5,marginRight:5}}/>
                    <input type='text' style={{padding:8,width:40,height:40,borderRadius:5,marginRight:5}}/>
                    <input type='text' style={{padding:8,width:40,height:40,borderRadius:5,marginRight:5}}/>
                    <input type='text' style={{padding:8,width:40,height:40,borderRadius:5,marginRight:5}}/>
                    <input type='text' style={{padding:8,width:40,height:40,borderRadius:5,marginRight:5}}/>
                  </div>
            </div>
            <button style={{padding:"5px 15px",marginBottom:10,width:100,background:'#3f1a73',color:'white',borderRadius:15}}>Verify</button>

          
        </div> 
        </div> 
    </div> 
   </div>
  
  )
}

export default Login
