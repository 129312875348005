import React from 'react'

const Mheader = (props) => {

    const Ismobile = props.isMobile

  return (
    <div style={{
        display: Ismobile?'block':'flex',
        background:'white',
        alignItems:'center',
        justifyContent:'space-evenly',
        padding:'50px 10%',
        textAlign:Ismobile?'center':null,
      }}>
        
  
        <div style={{width:Ismobile?'auto':'50%'}}>
          <img style={{width:Ismobile?'80%':'auto'}} src='https://www.rbs.co.uk/content/dam/natwest/assets/personal/email/png-illustrations/image.dim.480.illus-email-yellow-signpost-cass-200x200x2.png' alt='' />
        </div>


        <div style={{width:Ismobile?'auto':'50%'}}>
          <h3 style={{color:'black',margin:0,fontWeight:400}}>Proud Partner of Team GB</h3>
          <h1 style={{
            color:'black',
            fontSize:Ismobile?'2rem':'3rem',
            margin:'10px 0px 30px 0px'
          }}>Whatever your goals, let's get going</h1>
  
          <button className='headerbtn'>Find out more</button>
        </div>
      </div>
  )
}

export default Mheader
