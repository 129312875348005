import React from 'react'

const Products = (props) => {

    const Ismobile = props.isMobile

  return (
    <div style={{
        padding:'100px 10%',
        display:'flex',
        justifyContent:'space-around',
        flexWrap:'wrap',
        background:'#F9EFFF'
    }}>

        <div style={{textAlign:'center',margin:Ismobile?30:0}}>
        <i style={{
            fontSize:50,
            color:'#1778CE',
        }} class="fa-brands fa-docker"></i>
        <h3 style={{margin:'10px 0px'}}>docker</h3>
        </div>

        <div style={{textAlign:'center',margin:Ismobile?30:0}}>
        <i style={{
            fontSize:50,
            color:'#17CE73',
        }} class="fa-solid fa-rocket"></i>
        <h3 style={{margin:'10px 0px'}}>docker</h3>
        </div>

        <div style={{textAlign:'center',margin:Ismobile?30:0}}>
        <i style={{
            fontSize:50,
            color:'#CE2217',
        }} class="fa-solid fa-map-location-dot"></i>
        <h3 style={{margin:'10px 0px'}}>docker</h3>
        </div>

        <div style={{textAlign:'center',margin:Ismobile?30:0}}>
        <i style={{
            fontSize:50,
            color:'#17CEC6',
        }} class="fa-solid fa-business-time"></i>
        <h3 style={{margin:'10px 0px'}}>docker</h3>
        </div>

        <div style={{textAlign:'center',margin:Ismobile?30:0}}>
        <i style={{
            fontSize:50,
            color:'#1762CE',
        }} class="fa-solid fa-comments-dollar"></i>
        <h3 style={{margin:'10px 0px'}}>docker</h3>
        </div>

        <div style={{textAlign:'center',margin:Ismobile?30:0}}>
        <i style={{
            fontSize:50,
            color:'#17CE5C',
        }} class="fa-solid fa-virus-covid"></i>
        <h3 style={{margin:'10px 0px'}}>docker</h3>
        </div>
      
    </div>
  )
}

export default Products
