import React from 'react'
import './footer.css'
const Footer = (props) => {


  return (
   <footer class="footer" style={{marginTop:70}}>
   <div class="container">
      <div class="row">
         <div class="footer-col" data-aos="flip-left">
            <h4>company</h4>
            <ul>
               <li onClick={()=>props.sety('about')}><a>about</a></li>
               <li onClick={()=>props.sety('service')}><a>our services</a></li>
               <li><a href='/privacy-policy'>privacy policy</a></li>
               {/* <li onClick={()=>props.sety('')}><a>affiliate program</a></li> */}
            </ul>
         </div>
         <div class="footer-col" data-aos="flip-left">
            <h4>Explore</h4>
            <ul>
               <li ><a >What we do</a></li>
               <li ><a >Who we are</a></li>
               <li ><a >Insights</a></li>
               <li ><a >Careers</a></li>
               <li ><a >Newsroom</a></li>
            </ul>
         </div>
         <div class="footer-col" data-aos="flip-left">
            <h4>Contact Us</h4>
            <ul>
               <li><a onClick={()=>window.location.href = 'https://mail.google.com/mail/?view=cm&fs=1&to=support@duniyatech.com'}>support@duniyatech.com</a></li>
               <li><a href="#">+91 6265 578 975</a></li>
            </ul>
         </div>
         {/* <div class="footer-col" data-aos="flip-left">
            <h4>follow us</h4>
            <div class="social-links">
               <a href="https://www.facebook.com/profile.php?id=61559931133267"><i class="fab fa-facebook-f"></i></a>
               <a href="https://x.com/Duniyape"><i class="fab fa-twitter"></i></a>
               <a href="https://www.instagram.com/duniyatechsolution/"><i class="fab fa-instagram"></i></a>
               <a href="https://www.linkedin.com/in/duniyape-buisness-solution-956297317/"><i class="fab fa-linkedin-in"></i></a>
            </div>
         </div> */}
      </div>
   </div>
</footer>
  )
}

export default Footer
