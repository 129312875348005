import React from 'react';
import './Btn.css'
import { Link } from 'react-router-dom';

const Navbar = (props) => {

  const Ismobile = props.isMobile


  return (
    <div>
      {!Ismobile&&<div style={{
        background:'#5E10B1',
        height:48,
        width:'100vw',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
                }}>

          <ul style={{
            letterSpacing:2,
            display:'flex',
            alignItems:'center',
            listStyleType:'none',
            color:'white',
            fontSize:12,
            height:48,
            margin:0,
            paddingLeft:'10%'
          }}>
            <li class="fromLeft hh1" style={{padding:'0px 20px'}}>Personal</li>
            <li class="fromLeft hh1" style={{padding:'0px 20px'}}>Premier</li>
            <li class="fromLeft hh1" style={{padding:'0px 20px'}}>Business</li>
          </ul>
          

          <div style={{
            marginRight:'10%',
            border:'2px solid white',
            borderRadius:2
          }}>
            <i style={{padding:'8px 10px 8px 20px',color:'white',fontSize:12}} class="fa-solid fa-lock"></i>
            <Link to='login'><a style={{
              padding:'5px 20px 5px 0px',
              color:'white',
              fontSize:12,
              fontWeight:800
            }}>Log in</a></Link>
          </div>

                </div>}
                {/* <div style={{
            height:60,
            background:'#3F1A73',
            display:'flex',
            alignItems:'center',
            justifyContent:Ismobile?'end':'end'
          }} >
            
             <div style={{display:'flex',alignItems:'center',marginRight:10}}>
              <label style={{color:'white',marginRight:10}}>Customer ID</label>
              <input type='text' placeholder='User ID' style={{padding:8}}/>
            </div> 

            <button className='obutton'>Get OTP</button>

            <div style={{display:'flex',alignItems:'center',marginRight:10}}>
              <label style={{color:'white',marginRight:10}}>OTP</label>
              <input type='text' placeholder='6 digit number' style={{padding:8}}/>
            </div>

            <button className='obutton'>Verify</button>
                  </div> */}



          <div style={{
            height:60,
            background:'#7e2fd3',
            display:'flex',
            alignItems:'center',
            justifyContent:Ismobile?'space-between':'space-between'
          }}>


            



            <div style={{
              display:'flex',
              alignItems:'center',
              color:'white'
            }}>


              {Ismobile&&<div>
                <i style={{fontSize:18,marginLeft:30}} class="fa-solid fa-bars-staggered"></i>
              </div>}

              <div style={{display:'flex',alignItems:'center',marginRight:Ismobile?10:20}}>
              <i style={{margin:'0px 10px 0px 30px',fontSize:20}} class="fa-brands fa-rockrms"></i>
              <div>
              <p style={{margin:0,fontSize:14,fontWeight:800}}>Apnabank</p>
              <p style={{margin:0,fontSize:14,fontWeight:800}}>Customer</p>
              </div>
              </div>

            
              {!Ismobile&&<><a style={{padding:'0px 20px'}}>Product</a>
              <a style={{padding:'0px 20px'}}>Help and support</a>
              <a style={{padding:'0px 20px'}}>Banking with us</a></>}


            </div>

            {
              Ismobile&&
                <div style={{
                  marginRight:30,
            border:'2px solid white',
            borderRadius:2
          }}>
            <i style={{padding:'8px 10px 8px 20px',color:'white',fontSize:12}} class="fa-solid fa-lock"></i>
            <a style={{
              padding:'5px 20px 5px 0px',
              color:'white',
              fontSize:12,
              fontWeight:800
            }}>Login</a>
          </div>
            }

          

</div>

      
    </div>
  )
}

export default Navbar
