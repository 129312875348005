import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar';
import Products from './components/product/Products';
import Mheader from './components/middleheader/Mheader';
import ProjectView from './components/projectview/ProjectView';
import Footer from './components/footer/Footer';
import { useEffect, useState } from 'react';
import Header from './components/header/Header';
import Login from './components/login/Login';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

function App() {


  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root isMobile={isMobile}/>,
    },
    {
      path: "/login",
      element: <Login/>,
    },
  ]);
  


  return (

    <RouterProvider router={router} />
    
  );
}


const Root=(props)=>{

  const isMobile = props.isMobile

  return(
<div>
      <Navbar isMobile={isMobile}/>
      <Header isMobile={isMobile}/>
      <Products isMobile={isMobile}/>
      <Mheader isMobile={isMobile}/>
      <ProjectView isMobile={isMobile}/>
      <Footer/>
    </div>
  )
}

export default App;
